// ************** local *****************
// export const API_URL = `${
//   window.location.hostname === "localhost"
//     ? "http://localhost:4000/api/v1/"
//     : process.env.REACT_APP_API_URL
//     ? process.env.REACT_APP_API_URL
//     : "http://localhost:4000/api/v1/"
// }`;



// ************** dev *****************
//  export const API_URL = "https://staging.finlotax.com:4000/api/v1/";

// ************** production *****************
 export const API_URL = process.env.REACT_APP_API_URL;

// *******************************

export const adminEmail = "admin@finlo.com";
